








































































import { Component, Vue } from 'vue-property-decorator';
import { SsrCtx, WithAsyncData, WithMeta } from '@/core/vue.types';
import Breadcrumbs from '@/themes/v1/components/Breadcrumbs.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import StatusLabel from '@/themes/v1/components/StatusLabel.vue';
import NeedSomethingElse from '@/themes/v1/components/NeedSomethingElse.vue';
import { getModule } from 'vuex-module-decorators';
import { universalRedirect } from '@/themes/v1/fns/router';
import { PlayerStore } from '@/themes/v1/stores/player.store';
import {
  TemporaryProblemsService,
  TemporaryProblemViewModel,
} from '@/modules/sts/temporary-problems';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { TicketsStore } from '@/themes/v1/stores/tickets.store';
import { TicketCreatorService } from '@/modules/sts/creator/tickets-creator.service';
import { TicketCreatorOptions } from '@/modules/sts/creator/creator-options.model';
import ScrollTop from '@/themes/v1/components/ScrollTop.vue';
import { SystemStore } from '@/core/system';
import { MetaInfo } from 'vue-meta';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  components: {
    ScrollTop,
    NeedSomethingElse,
    StatusLabel,
    BoxContent,
    Box,
    Breadcrumbs,
  },
  directives: { TestLocator },
})
export default class ProblemsList
  extends Vue
  implements WithAsyncData, WithMeta {
  problems: TemporaryProblemViewModel[] = [];
  platform?: string;
  systemStore = getModule(SystemStore, this.$store);

  async asyncData(ctx: SsrCtx): Promise<any> {
    const platform = ctx.route.params.gamePlatform;
    const lang = ctx.route.params.lang;

    const ticketsStore = getModule(TicketsStore, ctx.store);
    let creationOptions: TicketCreatorOptions;
    if (ticketsStore.language == lang) {
      creationOptions = ticketsStore.options as TicketCreatorOptions;
    } else {
      const createrService = resolveFromCtx<TicketCreatorService>(
        'ticketCreatorService',
        ctx
      );
      creationOptions = await createrService.getOptions(lang);
      ticketsStore.setOptions({ lang, options: creationOptions });
    }
    const platformName = creationOptions?.platforms?.find(
      (x) => x.tag === platform
    )?.title;

    const playerStore = getModule(PlayerStore, ctx.store);
    if (playerStore.platformType !== platform) {
      playerStore.setPlatform(platform);
    }

    const temporaryProblemsService = resolveFromCtx<TemporaryProblemsService>(
      'temporaryProblemsService',
      ctx
    );
    let problems: TemporaryProblemViewModel[] = [];
    try {
      problems = await temporaryProblemsService.getList(
        ctx.app.$i18n.locale,
        platform
      );
    } catch (e) {
      console.error(e);
      universalRedirect(ctx, 'tickets/create');
      return {};
    }

    if (!problems.length) {
      universalRedirect(ctx, 'tickets/create');
      return {};
    }
    if (problems.length === 1) {
      const [first] = problems;
      universalRedirect(ctx, `problems/${platform}/${first.id}`);
      return {};
    }
    return { problems, platform: platformName };
  }

  getActualStatus(problem: TemporaryProblemViewModel): string {
    return problem.messages.length > 0
      ? problem.messages[problem.messages.length - 1].problemStatusText
      : problem.description.problemStatusText;
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$t('meta.createTicket.title') as string,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$t('meta.createTicket.description') as string,
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.$t('meta.createTicket.keywords') as string,
        },
      ],
    };
  }
}
