<template>
  <div class="status-label">
    <div class="status-label__bg"></div>
    <div class="status-label__text"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'StatusLabel',
};
</script>

<style scoped></style>
